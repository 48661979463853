.button {
  $root: &;
  $arrowHoverTransform: translateX(4px);
  font-family: inherit;
  transition: background .15s linear, border-color .15s linear, color .15s linear, box-shadow .15s linear;
  cursor: pointer;
  line-height: $buttonPrimaryHeightMobile;
  text-align: center;
  padding: 0 8px;
  width: auto;
  position: relative;
  color: $buttonTextColor;
  font-weight: $fontWeightRegular;
  border: $buttonBorderWidth solid $buttonBorderColor;
  border-radius: $buttonBorderRadius;
  background: $buttonBackgroundColor;
  text-decoration: none;
  box-shadow: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 1.5px;
  font-size: $buttonFontSizeMobile;

  --button-primary-text-color: #{$buttonPrimaryTextColor};
  --button-primary-background-color: #{$buttonPrimaryBackgroundColor};
  --button-primary-border-color: #{$buttonPrimaryBorderColor};
  --button-primary-hover-background-color: #{$buttonPrimaryHoverBackgroundColor};
  --button-primary-hover-text-color: #{$buttonPrimaryHoverTextColor};
  --button-primary-active-background-color: #{$buttonPrimaryActiveBackgroundColor};
  --button-secondary-text-color: #{$buttonSecondaryTextColor};
  --button-secondary-background-color: #{$buttonSecondaryBackgroundColor};
  --button-secondary-border-color: #{$buttonSecondaryBorderColor};
  --button-secondary-hover-background-color: #{$buttonSecondaryHoverBackgroundColor};
  --button-secondary-hover-text-color: #{$buttonSecondaryHoverTextColor};
  --button-secondary-active-background-color: #{$buttonSecondaryActiveBackgroundColor};
  --button-light-text-color: #{$buttonSecondaryTextColor};
  --button-light-background-color: #{$buttonSecondaryBackgroundColor};
  --button-light-border-color: #{$buttonSecondaryBorderColor};
  --button-light-hover-background-color: #{$buttonSecondaryHoverBackgroundColor};
  --button-light-hover-text-color: #{$buttonSecondaryHoverTextColor};
  --button-light-active-background-color: #{$buttonSecondaryActiveBackgroundColor};
  --button-dark-text-color: #{$whiteColor};
  --button-dark-background-color: #{$blackColor};
  --button-dark-border-color: #{$buttonSecondaryBorderColor};
  --button-dark-hover-background-color: #{$blackHoverColor};
  --button-dark-hover-text-color: #{$whiteColor};
  --button-dark-active-background-color: #{$blackActiveColor};

  @media (min-width: $screen-tablet-portrait) {
    font-size: $buttonFontSizeDesktop;
    line-height: $buttonPrimaryHeightDesktop;
    letter-spacing: 2px;
  }

  @media (min-width: $screen-tablet-landscape) {
    padding: 0 24px;
  }

  &::after {
    transition: color .15s linear, transform .15s ease-in-out;
    font-weight: $fontWeightBold;
  }

  &:focus {
    outline: none;
    box-shadow: $buttonShadow;
  }

  &--active,
  &:active {
    outline: none;
    box-shadow: $buttonShadowActive;
  }

  &--loading:not(.button--hide-loading) {
    pointer-events: none;
    color: transparent;

    @include loader();

    &:hover {
      box-shadow: none;
    }

    &.button--primary {
      transition: background 0ms linear, border-color 0ms linear, color 0ms linear, box-shadow 0ms linear;

      &::before,
      &::after {
        transition: background 0ms linear, border-color 0ms linear, color 0ms linear, box-shadow 0ms linear;
        color: $whiteColor;
      }
    }

    &.button--secondary {
      transition: background 0ms linear, border-color 0ms linear, color 0ms linear, box-shadow 0ms linear;

      &::before,
      &::after {
        transition: background 0ms linear, border-color 0ms linear, color 0ms linear, box-shadow 0ms linear;
        color: $blackColor;
      }
    }
  }

  &--link {
    background: transparent;
    display: inline-block;
    width: auto;
    border: 0 none;
    color: $grayDarkColor;
    text-decoration: underline;
    padding: 0;
    letter-spacing: 0;

    &:hover {
      color: $grayDarkColor;
      background: transparent;
      text-decoration: underline;
      box-shadow: none;
    }

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }

    &-arrow {
      @include iconFont(arrowRight, after);

      &::after {
        display: inline-block;
        padding-left: 8px;
        font-size: 10px;
        text-decoration: none;
      }
    }
  }

  &--cancel {
    background: transparent !important;
    display: inline-block;
    width: auto;
    border: 0 none;
    color: $brownMediumColor !important;
    text-decoration: underline !important;
    padding: 0 !important;
    letter-spacing: .2px !important;
    font-weight: $fontWeightRegular !important;
    text-transform: uppercase;
    text-align: left !important;
    font-size: 13px !important;
    line-height: 24px;

    @media (min-width: $screen-mobile-large) {
      text-align: right !important;
      line-height: $buttonPrimaryHeightDesktop;
    }

    &:hover {
      color: $brownMediumHoverColor !important;
      background: transparent;
      text-decoration: underline;
      box-shadow: none;
    }

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
      color: $brownMediumActiveColor !important;
    }

    &::after {
      display: none !important;
    }
  }

  &--delete {
    color: $whiteColor !important;
    background: $brownMediumColor !important;

    body:not(.is_touch) & {
      &:hover {
        background: $brownMediumColor !important;
      }
    }

    &:active {
      background: $brownMediumColor !important;
    }
  }

  &--height-auto {
    height: auto;
    line-height: normal;
  }

  &--primary,
  &--primary-small {
    background: var(--button-primary-background-color);
    border-color: var(--button-primary-border-color);
    color: var(--button-primary-text-color);
    display: inline-block;
    width: auto;
    text-transform: uppercase;
    padding: 0 16px 0 24px;
    font-size: $buttonFontSizeMobile;
    letter-spacing: 1px;
    line-height: 32px;
    height: 32px;
    min-width: 136px;

    @include iconFont(arrowRight, after) {
      display: inline-block;
      height: 14px;
      font-size: 12px;
      padding: 0 0 0 4px;
      vertical-align: middle;

      @media (min-width: $screen-tablet-portrait) {
        padding: 0 0 0 6px;
        height: 18px;
        font-size: 15px;
      }
    }

    @media (min-width: $screen-tablet-portrait) {
      min-width: 200px;
      padding: 0 32px 0 40px;
      width: auto;
      line-height: 44px;
      height: 44px;
      font-size: $buttonFontSizeDesktop;
      letter-spacing: 1.6px;
    }

    body:not(.is_touch) & {
      &.button--hover,
      &:hover {
        background: var(--button-primary-hover-background-color);
        color: var(--button-primary-hover-text-color);

        &::after {
          transform: $arrowHoverTransform;
        }
      }
    }

    &.button--active,
    &:active {
      background: var(--button-primary-active-background-color);
      color: var(--button-primary-text-color);

      &::after {
        transform: $arrowHoverTransform;
      }
    }

    &.button--loading {
      &::before,
      &::after {
        color: var(--button-primary-text-color);
        background: transparent;
      }
    }

    &.form__submit-button {
      padding: 0 32px;
    }

    .is_fire-fox & { // stylelint-disable-line
      text-overflow: clip;
    }
  }

  &--secondary,
  &--secondary-small,
  &--secondary-smaller {
    background: var(--button-secondary-background-color);
    color: var(--button-secondary-text-color);
    border-color: var(--button-secondary-border-color);
    display: inline-block;
    width: auto;
    text-transform: uppercase;
    padding: 0 16px 0 24px;
    font-size: $buttonFontSizeMobile;
    letter-spacing: 1px;
    line-height: 32px;
    height: 32px;
    min-width: 136px;

    @include iconFont(arrowRight, after) {
      display: inline-block;
      height: 14px;
      font-size: 12px;
      padding: 0 0 0 4px;
      vertical-align: middle;

      @media (min-width: $screen-tablet-portrait) {
        padding: 0 0 0 6px;
        height: 18px;
        font-size: 15px;
      }
    }

    @media (min-width: $screen-tablet-portrait) {
      width: auto;
      min-width: 200px;
      padding: 0 32px 0 40px;
      line-height: 44px;
      height: 44px;
      font-size: $buttonFontSizeDesktop;
      letter-spacing: 1.6px;
    }

    body:not(.is_touch) & {
      &.button--hover,
      &:hover {
        background: var(--button-secondary-hover-background-color);
        color: var(--button-secondary-hover-text-color);

        &::after {
          transform: $arrowHoverTransform;
        }
      }
    }

    &.button--active,
    &:active {
      background: var(--button-secondary-active-background-color);
      color: var(--button-secondary-text-color);

      &::after {
        transform: $arrowHoverTransform;
      }
    }

    &.button--loading {
      &::before,
      &::after {
        color: var(--button-secondary-text-color);
        background: transparent;
      }
    }

    .is_fire-fox & { // stylelint-disable-line
      text-overflow: clip;
    }
  }

  &--standard,
  &.form__submit-button,
  .form__group--row & {
    @media (max-width: $screen-mobile-landscape-max) {
      width: 100%;
      min-width: 200px;
      padding: 0 8px 0 16px;
      line-height: 44px;
      height: 44px;
      font-size: $buttonFontSizeDesktop;

      &:not(.button--loading) {
        &::after {
          padding: 0 0 0 6px;
          height: 18px;
          font-size: 15px;
        }
      }
    }
  }

  &--primary-small,
  &--secondary-small,
  &--secondary-smaller {
    padding: 0 16px 0 24px;
    width: auto;
    font-size: 13px;
    font-weight: $fontWeightRegular;
    line-height: 32px;
    height: 32px;
    min-width: 136px;

    &::after {
      height: 14px;
      font-size: 12px;
      padding: 0 0 0 4px;
    }
  }

  &--text {
    font-size: 16px;
    background: none;
    color: $brandPrimaryColor;
    display: inline-block;
    font-weight: $fontWeightBold;
    text-transform: uppercase;
    text-align: left;
    width: auto;
    height: auto;
    border-bottom: transparent 2px solid;
    padding: 8px 2px 6px 0;
    line-height: 120%;
    white-space: inherit;

    @media (min-width: $screen-tablet-landscape) {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
    }

    &::after {
      transition: color .15s linear, transform .15s ease-in-out;
    }

    &--hover,
    &:hover {
      background: none;
      color: $brandPrimaryColor;
      border-color: currentColor;

      &::after {
        transform: $arrowHoverTransform;
      }
    }

    &-arrow {
      @include iconFont(arrowRightBold, after) {
        display: inline-block;
        padding-left: 12px;
        font-weight: $fontWeightBold;
        font-size: 8px;

        @media (min-width: $screen-tablet-portrait) {
          padding-left: 8px;
          font-size: 10px;
        }
      }
    }

    .is_fire-fox & { // stylelint-disable-line
      text-overflow: clip;
    }
  }

  &--light,
  &--light-small,
  &--light-smaller {
    background: var(--button-light-background-color);
    color: var(--button-light-text-color);
    border-color: var(--button-light-border-color);
    display: inline-block;
    width: auto;
    text-transform: uppercase;
    padding: 0 16px 0 24px;
    font-size: $buttonFontSizeMobile;
    letter-spacing: 1.6px;
    line-height: 32px;
    height: 32px;
    min-width: 136px;

    @media (min-width: $screen-tablet-portrait) {
      width: auto;
      min-width: 200px;
      padding: 0 32px 0 40px;
      line-height: 44px;
      height: 44px;
      min-height: 44px;
      font-size: $buttonFontSizeDesktop;
    }

    body:not(.is_touch) & {
      &.button--hover,
      &:hover {
        background: var(--button-light-hover-background-color);
        color: var(--button-light-hover-text-color);
      }
    }

    &.button--active,
    &:active {
      background: var(--button-light-active-background-color);
      color: var(--button-light-text-color);
    }

    &.button--loading {
      &::before,
      &::after {
        color: var(--button-light-text-color);
        background: transparent;
      }
    }

    .is_fire-fox & { // stylelint-disable-line
      text-overflow: clip;
    }
  }

  &--dark,
  &--dark-small,
  &--dark-smaller {
    background: var(--button-dark-background-color);
    color: var(--button-dark-text-color);
    border-color: var(--button-dark-border-color);
    display: inline-block;
    width: auto;
    text-transform: uppercase;
    padding: 0 16px 0 24px;
    font-size: $buttonFontSizeMobile;
    letter-spacing: 1.6px;
    line-height: 32px;
    height: 32px;
    min-width: 136px;

    @media (min-width: $screen-tablet-portrait) {
      width: auto;
      min-width: 200px;
      padding: 0 32px 0 40px;
      line-height: 44px;
      height: 44px;
      min-height: 44px;
      font-size: $buttonFontSizeDesktop;
    }

    body:not(.is_touch) & {
      &.button--hover,
      &:hover {
        background: var(--button-dark-hover-background-color);
        color: var(--button-dark-hover-text-color);
      }
    }

    &.button--active,
    &:active {
      background: var(--button-dark-active-background-color);
      color: var(--button-dark-text-color);
    }

    &.button--loading {
      &::before,
      &::after {
        color: var(--button-dark-text-color);
        background: transparent;
      }
    }

    .is_fire-fox & { // stylelint-disable-line
      text-overflow: clip;
    }
  }

  &--alt {
    background: $buttonBackgroundColor;
    border-color: $buttonBorderColor;
    color: $buttonTextColor;
    width: 100%;

    &::after {
      color: inherit;
    }

    &:hover {
      color: $buttonHoverTextColor;
      background: $buttonHoverBackgroundColor;
      border-color: $buttonHoverBorderColor;

      &::after {
        color: inherit;
      }
    }
  }

  &--add-to-basket {
    padding: 0;
    width: 40px;
    height: 40px;
    color: $whiteColor;
    border-radius: $buttonCounterBorderRadius;
    background: $brownColor;
    display: flex;
    justify-content: center;
    align-items: center;

    @include iconFont(cart, after) {
      font-size: 25px;
      font-weight: $fontWeightRegular;
    }

    body:not(.is_touch) & {
      &:hover {
        background: $brownHoverColor;
      }
    }

    &:active {
      background: $brownActiveColor;
    }

    &::before,
    &::after {
      color: $whiteColor;
    }

    &::after {
      font-size: 24px;
      color: $whiteColor;
      width: 24px;
    }

    &.button--loading {
      &::before,
      &::after {
        color: $whiteColor;
      }
    }
  }

  &--disabled {
    cursor: not-allowed;
    background: $buttonDisabledBackgroundColor;
    border-color: $buttonDisabledBorderColor;
    color: $buttonDisabledTextColor;
    box-shadow: none !important;

    &::after {
      color: $buttonDisabledTextColor;
    }

    body:not(.is_touch) & {
      &:hover {
        background: $buttonDisabledBackgroundColor;
        border-color: $buttonDisabledBorderColor;
        color: $buttonDisabledTextColor;
        box-shadow: none;
      }
    }
  }

  &--paypal-checkout {
    background-color: $paypalcheckout;
    padding: 0;
    max-width: 210px;
    height: $buttonPrimaryHeightMobile;

    @media (min-width: $screen-tablet-portrait) {
      height: $buttonPrimaryHeightDesktop;
    }

    &::after {
      display: none;
    }

    i {
      display: inline-block;
      padding: 0;
      margin: 0;
    }
  }

  &--apple-pay-checkout {
    background-color: $paypalcheckout;
    padding: 0;
    min-height: 44px;
    min-width: 50%;
    height: $buttonPrimaryHeightDesktop;

    @media (min-width: $screen-tablet-portrait) {
      height: $buttonPrimaryHeightDesktop;
    }

    &::after {
      display: none;
    }

    i {
      display: inline-block;
      padding: 0;
      margin: 0;
    }
  }

  &--hide {
    display: none !important;
  }

  &--inactive {
    opacity: .4;
  }
}
