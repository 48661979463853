.carousel {
  $buttonSizeM: 40px;
  $buttonSizeT: 40px;
  $buttonSizeD: 40px;
  position: relative;

  &__wrapper {
    width: calc(100% + 20px);
    margin: 0 -10px;
    overflow: hidden;

    @media (min-width: $screen-mobile-sm) {
      width: calc(100% + 40px);
      margin: 0 -20px;
    }

    @media (min-width: $screen-tablet-portrait) {
      width: 100%;
      margin: 0 auto;
    }

    .carousel--with-border & {
      box-shadow: 0 0 0 1px $carouselButtonPrimaryColor;
    }

    .carousel__no-slider & {
      width: 100%;
      margin: 0 auto;
    }
  }

  &__container {
    transform: translate3d(0, 0, 0);
    position: relative;
    width: 100%;
    display: flex;
    transition-property: transform;
    align-items: stretch;
  }

  &__slide {
    position: relative;
    flex-shrink: 0;
    z-index: 2;

    &--active {
      z-index: 3;
    }

    .teasergroup_teaser {
      &__item {
        height: 100%;

        & > div {
          height: 100%;
        }

        .image-text-action-teaser__link {
          height: 100%;
          display: flex;
          flex-direction: column;

          &__box,
          .image-text-action-teaser__box {
            flex-grow: 1;
          }
        }
      }
    }
  }

  &__button {
    color: $textBaseColor;
    z-index: 1;
    width: 85px;
    height: $buttonSizeM;
    text-align: center;
    cursor: pointer;

    @media (min-width: $screen-mobile-sm) {
      width: 90px;
    }

    @media (min-width: $screen-tablet-portrait) {
      position: absolute;
      color: $carouselButtonTextColor;
      top: 0;
      height: 100%;
      width: $buttonSizeT;
      min-height: $buttonSizeT;
    }

    @media (min-width: $screen-desktop-xl) {
      width: $buttonSizeD;
      min-height: $buttonSizeD;
    }

    &:visited {
      .carousel__button-icon {
        color: $carouselButtonTextColor;
        background: $carouselButtonPrimaryColor;
      }
    }

    body:not(.is_touch) & {
      &:hover {
        @media (min-width: $screen-tablet-portrait) {
          .carousel__button-icon {
            color: $carouselButtonHoverTextColor;
            background: $carouselButtonHoverBackgroundColor;
            transform: scale(1.2);
          }
        }
      }
    }

    &:active {
      @media (min-width: $screen-tablet-portrait) {
        .carousel__button-icon {
          color: $carouselButtonActiveTextColor;
          background: $carouselButtonActiveBackgroundColor;
          transform: scale(1.1);
        }
      }
    }

    &:focus {
      outline: 0 solid transparent;
    }

    &--prev {
      @media (min-width: $screen-tablet-portrait) {
        left: -20px;
      }

      @media (min-width: $screen-desktop-xxl) {
        left: -65px;
      }

      .carousel__button-icon {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media (min-width: $screen-tablet-portrait) {
          justify-content: center;
        }

        @include iconFont(arrowLeft) {
          font-size: 24px;
          left: 48%;
        }
      }
    }

    &--next {
      @media (min-width: $screen-tablet-portrait) {
        right: -20px;
      }

      @media (min-width: $screen-desktop-xxl) {
        right: -65px;
      }

      .carousel--fade-white & {
        background: linear-gradient(to left, $whiteColor 0%, transparent 100%); // stylelint-disable-line  plugin/no-unsupported-browser-features
      }

      .carousel__button-icon {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media (min-width: $screen-tablet-portrait) {
          justify-content: center;
        }

        @include iconFont(arrowRight) {
          font-size: 24px;
        }
      }

      .carousel--with-border & {
        right: -20px;

        @media (min-width: $screen-desktop-xl) {
          right: -33px;
        }
      }
    }

    &.swiper-button-disabled {
      opacity: 0;
    }
  }

  &__button-icon {
    text-decoration: none;
    color: $textBaseColor;
    display: inline-block;
    cursor: pointer;
    width: 100%;
    height: $buttonSizeM;
    transition: all 300ms ease-in-out;
    left: 0;
    transform: scale(1);

    &::before {
      line-height: 1;
      font-size: 24px;
      color: $textBaseColor;

      @media (min-width: $screen-tablet-portrait) {
        position: absolute;
        left: 52%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $carouselButtonTextColor;
      }
    }

    @media (min-width: $screen-tablet-portrait) {
      width: $buttonSizeT;
      height: $buttonSizeT;
      position: absolute;
      top: calc(50% - 20px);
      z-index: 4;
      color: $carouselButtonTextColor;
      background: $carouselButtonPrimaryColor;
      border-radius: $carouselBorderRadius;
      box-shadow: $carouselButtonBoxShadow;
    }

    @media (min-width: $screen-desktop-xl) {
      width: $buttonSizeD;
      height: $buttonSizeD;
    }
  }

  &__pagination-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 16px 0 0;
    align-items: center;

    @media (min-width: $screen-tablet-portrait) {
      display: block;
      margin: 0;
    }

    .image-slider-teaser & {
      margin: 0;
    }
  }

  &__pagination {
    width: 100% !important;
    padding: 0;
    display: none;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    &--show {
      display: block;
      visibility: hidden;
    }

    &--visible {
      visibility: visible;
    }
  }

  &__page-info {
    color: $textBaseColor;
    line-height: 110%;

    @media (min-width: $screen-tablet-portrait) {
      display: none;
    }

    span {
      font-size: 13px;
      color: $textBaseColor;
      padding: 0 3px;

      &:first-child {
        font-size: 17px;
      }
    }
  }

  &__pagination-bullet {
    display: none;
    background: $carouselPaginationBackgroundColor;
    margin: 0 2px;
    height: 3px;
    width: 20px;

    &--current-main,
    &--current-prev,
    &--current-prev-prev,
    &--current-next,
    &--current-next-next {
      display: block;
    }

    &--current {
      background: $carouselPaginationHighlightColor;
    }
  }

  &__product-box {
    .product-box__inner {
      border: 0 none;
    }
  }
}

// PLACEHOLDER

/* stylelint-disable-next-line */
carousel,
[is="carousel"] {
  display: flex;
  width: calc(100% - 40px);
  margin: 0 auto;
}
