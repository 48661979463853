@mixin teaserContainer {
  display: flex;
  flex-flow: column;
  padding-top: 32px;
  padding-bottom: 32px;

  @media (min-width: $screen-tablet-portrait) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .tabs-component-panel & {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@mixin teaserText {
  text-align: left;
  font-size: 13px;
  font-family: $fontPrimaryFamily;
  line-height: 1.3;
  color: $textBaseColor;

  @media (min-width: $screen-tablet-portrait) {
    font-size: 15px;
  }

  @media (min-width: $screen-desktop) {
    font-size: 17px;
  }
}

@mixin teaserHeadline {
  order: 1;
  text-align: center;
  font-size: 24px;
  padding: 0 0 24px;
  color: $orangeColor;

  @media (min-width: $screen-tablet-portrait) {
    font-size: 34px;
  }

  @media (min-width: $screen-desktop) {
    font-size: 45px;
    padding: 0 0 32px;
  }
}

@mixin teaserLink {
  order: 3;
  text-align: center;
  margin-top: 24px;

  @media (min-width: $screen-tablet-portrait) {
    margin-top: 32px;
  }
}

@mixin teaserItemsContainer {
  width: auto;
  order: 2;
  position: relative;

  .tabs-component-panel & {
    margin-top: 0;
  }
}
