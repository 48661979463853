@import '../../utilities/scss/teasers/teaserLayout.scss';

.cinema-teaser {
  @include teaserContainer();

  .grid.teaser-in-column & {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__headline {
    font-family: $fontHeadlineFamily;
    font-size: 28px;
    line-height: 1;
    text-align: center;
    padding: 0 0 24px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 34px;
    }

    @media (min-width: $screen-desktop) {
      font-size: 45px;
      padding: 0 0 32px;
    }

    > .u-teaser-headline,
    > .u-teaser-headline-alternative-font {
      margin: 0;
      padding: 0;
    }

    > .u-text-l,
    > p {
      margin: 0 auto 16px;

      @media (min-width: $screen-tablet-landscape) {
        max-width: 940px;
      }
    }
  }

  &__link {
    @include teaserLink();

    .button {
      --button-secondary-text-color: #{$buttonPrimaryTextColor};
      --button-secondary-background-color: #{$buttonPrimaryBackgroundColor};
      --button-secondary-border-color: #{$buttonPrimaryBorderColor};
      --button-secondary-hover-background-color: #{$buttonPrimaryHoverBackgroundColor};
      --button-secondary-hover-text-color: #{$buttonPrimaryHoverTextColor};
      --button-secondary-active-background-color: #{$buttonPrimaryActiveBackgroundColor};
    }
  }

  &__items-container {
    @include teaserItemsContainer();
    width: 100%;

    &-white-text {
      .carousel__page-info {
        span {
          color: white;
        }
      }

      .carousel__button {
        .carousel__button-icon::before {
          @media (max-width: $screen-mobile-landscape-max) {
            color: white;
          }
        }
      }
    }
  }

  &__products {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;

    &.products-list--type_box {
      justify-content: flex-start;
    }
  }

  .product-box__inner {
    border: 0;
  }

  .products-list--type_list .products-list__element {
    &:last-child {
      padding-bottom: 0;
    }
  }

  &__section {
    body:not(.page-detail-view) & {
      width: 100%;
      position: relative;
      padding: 0;
      min-height: 200px;

      @media (min-width: $screen-tablet-landscape) {
        padding: 0;
      }

      &:nth-child(even),
      &:nth-child(odd) {
        background: inherit;

        .read-more {
          &__btn {
            background: inherit;
          }
        }
      }
    }
  }

  &__below {
    order: 5;
  }
}

.page--category-landingpage {
  .cinema-teaser {
    &__headline {
      padding: 0 0 8px;

      @media (min-width: $screen-desktop) {
        padding: 0 0 16px;
      }
    }
  }
}
