.headline {
  display: block;
  width: 100%;
  margin: 0;
  font-weight: $fontWeightRegular;
  font-family: $fontHeadlineFamily;

  h1,
  &--type_h1 {
    font-weight: $fontWeightBold;
    font-size: 40px;
    padding: 0 0 24px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 52px;
    }

    @media (min-width: $screen-desktop) {
      font-size: 64px;
      padding: 0 0 32px;
    }
  }

  h2,
  &--type_h2 {
    font-weight: $fontWeightBold;
    font-size: 28px;
    padding: 0 0 24px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 34px;
    }

    @media (min-width: $screen-desktop) {
      font-size: 45px;
      padding: 0 0 32px;
    }
  }

  &--alt {
    font-family: $fontSecondaryFamily;
  }

  &--orange {
    color: $orangeColor;
  }

  &--alternative {
    font-family: $fontSecondaryFamily;
    font-weight: $fontWeightRegular;
  }

  &--success {
    color: $successColor;
  }

  &--dark-shadow {
    background: linear-gradient(180deg, rgb(0 0 0 / .4), rgb(0 0 0 / 0)); // stylelint-disable-line plugin/no-unsupported-browser-features
  }

  &--type_form {
    font-size: $formHeadlineFontSizeMobile;
    margin: $formHeadlineMarginMobile;
    font-weight: $formHeadlineFontWheight;
    color: $formHeadlineColor;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $formHeadlineFontSizeDesktop;
      margin: $formHeadlineMarginDesktop;
    }
  }

  &--center {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &--big,
  &--extra-big {
    color: $brandSecondaryColor;
    font-weight: $headlineMainLineFontWeight !important;
    font-size: 35px !important;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 40px !important;
    }

    @media (min-width: $screen-laptop) {
      font-size: 60px !important;
    }
  }

  &--extra-big {
    @media (min-width: $screen-laptop) {
      font-size: 70px !important;
    }
  }
}
