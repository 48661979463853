@supports (background-image: image-set(url(../staticImages/shadow-leaf.webp) type("image/webp"))) {
  .page-shadow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    background: radial-gradient(ellipse at bottom left, #fff8, #fff0 50%); // stylelint-disable-line  plugin/no-unsupported-browser-features
    z-index: -1;

    &__leaf {
      .page--category-list & {
        @media screen and (max-width: 1023px) {
          display: none;
        }
      }
      position: absolute;
      width: 849px;
      height: 1286px;

      &::before {
        content: '';
        background: url(../staticImages/shadow-leaf.webp) no-repeat;
        background-size: contain;
        display: block;
        width: 100%;
        height: 100%;
        transform-origin: 0 40%;
        animation: shadowAnimation 25s ease-in-out infinite;
      }

      &--1 {
        right: -530px;
        top: -20%;
        transform: scaleX(-1) rotate(-20deg);
      }

      &--2 {
        left: -30%;
        top: 0;
        transform: rotate(300deg);
        display: none;

        &::before {
          animation-delay: 3s;
        }

        @media (min-width: 1100px) {
          display: block;
        }
      }
    }

    .page-detail-view & {
      display: none;
    }
  }
}

@keyframes shadowAnimation {
  0% {
    transform: none;
  }

  10% {
    transform: scale(.9);
  }

  30% {
    transform: scale(.8) skew(0, 4deg);
  }

  50% {
    transform: skew(0, 8deg);
  }

  60% {
    transform: skew(0, -2deg);
  }

  70% {
    transform: skew(0, 1deg);
  }

  78% {
    transform: skew(0, 5deg) scale(.9);
  }

  85% {
    transform: skew(0, -3deg);
  }

  92% {
    transform: skew(0, 1deg) scale(.9);
  }

  100% {
    transform: none;
  }
}
