.loader,
.loading {
  @include loader();

  picture & {
    height: 100%;
    min-height: 120px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.loading {
  min-height: 60px;
  margin: 30px 0;
  overflow: hidden;
}

@keyframes vinosLoaderGlasRotate {
  0% {
    transform: rotateZ(-8deg) translateX(-5px);
  }

  50% {
    transform: rotateZ(8deg) translateX(5px);
  }

  100% {
    transform: rotateZ(-8deg) translateX(-5px);
  }
}

@keyframes vinosLoaderGlasAnim {
  0% {
    content: map-get($icons, loaderGlasA);
    top: 50%;
  }

  10% {
    content: map-get($icons, loaderGlasB);
  }

  20% {
    content: map-get($icons, loaderGlasC);
  }

  25% {
    top: calc(50% + 3px);
  }

  30% {
    content: map-get($icons, loaderGlasB);
  }

  40% {
    content: map-get($icons, loaderGlasA);
  }

  50% {
    top: 50%;
  }

  60% {
    content: map-get($icons, loaderGlasB);
  }

  70% {
    content: map-get($icons, loaderGlasC);
  }

  75% {
    top: calc(50% - 2px);
  }

  80% {
    content: map-get($icons, loaderGlasB);
  }

  90% {
    content: map-get($icons, loaderGlasA);
  }

  100% {
    top: 50%;
  }
}

@keyframes vinosLoaderSwingRotate {
  0% {
    transform: rotateZ(-4deg) translate(-2px, 16px);
  }

  50% {
    transform: rotateZ(4deg) translate(2px, 16px);
  }

  100% {
    transform: rotateZ(-4deg) translate(-2px, 16px);
  }
}

@keyframes vinosLoaderSwingTop {
  0% {
    top: 50%;
  }

  25% {
    top: calc(50% + 2px);
  }

  50% {
    top: 50%;
  }

  75% {
    top: calc(50% - 2px);
  }

  100% {
    top: 50%;
  }
}
